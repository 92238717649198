import React from 'react';
import { useTranslation } from 'react-i18next';

import Permission from '@common/components/permission';
import { SearchBar } from '@common/components/search-bar';
import { EPermissions } from '@common/definitions';
import { FolderModalForm } from '../../forms/folder-modal-form';
import { Button } from '@common/components/button';
import { FileUploadForm } from '../../forms/file-upload';

import type { PartialBy } from '@common/types/util-types';
import type { Folder, DocumentsRouteParams } from '../../types';

type HeaderActionButtonsProps = {
  params: PartialBy<DocumentsRouteParams, 'filter' | 'networkId'>;
  personalFolder?: boolean;
  searchTerm?: string;
  onNewSearchTerm: (newSearchTerm: string | undefined | null) => void;
  currentFolder?: Folder;
  searchBoxPlaceholder: string;
  userId?: string;
};

export const DocumentsHeaderActionButtons = ({
  params,
  personalFolder = false,
  userId,
  searchTerm,
  onNewSearchTerm,
  currentFolder,
  searchBoxPlaceholder,
}: HeaderActionButtonsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <SearchBar
        placeholder={searchBoxPlaceholder}
        onSearch={onNewSearchTerm}
        defaultValue={searchTerm}
        key={`${params.filter}${params.folderId}`} // remounting when those change in order to clear the input
      />
      <Permission name={[EPermissions.NETWORK_DOCUMENTS_CREATE, EPermissions.ORGANISATION_DOCUMENTS_CREATE]}>
        <FolderModalForm currentFolder={currentFolder} userId={userId}>
          <Button size="large" iconRight="add">
            {t('documents:create_folder')}
          </Button>
        </FolderModalForm>
        <FileUploadForm
          parentFolderId={params.folderId}
          userId={userId}
          personalFolder={personalFolder || (!!currentFolder?.personal_root_user_id || params.folderId === 'personal')}
        />
      </Permission>
    </>
  );
};
