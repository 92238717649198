import { createSelector } from 'reselect';

import { findRelatedObjects } from '@utils/recursive-data';

import type { StoreState } from '@common/types/store';
import type { Document } from './types';

const getFolderKey = (state: StoreState, folderKey: string) => folderKey;
const getIncludePersonal = (s: StoreState, f: string, includePersonal: boolean) => includePersonal;
const getDocumentIds = (state: StoreState, folderKey: string) => state.documents.ids[folderKey];
const getDocumentItems = (state: StoreState) => state.documents.items;
const getDocumentById = (state: StoreState, id: string) => state.documents.items?.[id];
const getDocumentsNextCursors = (state: StoreState, folderKey: string): string | null | undefined => (
  state.documents.nextCursors[folderKey]
);

export const getRawDocumentItems = createSelector(getDocumentItems, (documentItems) => documentItems);

export const getDocuments = createSelector(
  [getDocumentIds, getDocumentItems, getFolderKey, getIncludePersonal],
  (ids, documentItems, folderKey, includePersonal = true): Document[] => {
    if (!ids) return [];

    const startFolder = documentItems?.[folderKey];
    const parents = startFolder ? [
      startFolder,
      ...(findRelatedObjects(startFolder, documentItems, 'parent_folder_id') || []),
    ] : [];

    const items = ids.map((id) => documentItems[id]).filter(Boolean);
    return items.filter(({ type }) => {
      return includePersonal || type !== 'personal_folder';
    }).map((item) => ({
      ...item,
      parent_folders: parents,
    }));
  },
);

export const getDocument = createSelector(
  [getDocumentById, getDocumentItems],
  (documentItem, documentItems): Document | undefined => {
    if (!documentItem) return undefined;

    const relatedDocuments = findRelatedObjects(documentItem, documentItems, 'parent_folder_id');

    return {
      ...documentItem,
      // @ts-expect-error
      parent_folders: relatedDocuments,
    };
  },
);

export const getNextCursor = createSelector(getDocumentsNextCursors, (nextCursor) => nextCursor);
