import React from 'react';
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form';
import { omit } from 'lodash';

import FileCropInput, { FileCropInputProps } from './file-crop-input';

const ReduxFormField = (props: WrappedFieldProps & FileCropInputProps) => {
  return (
    <FileCropInput
      {...props.input}
      {...omit(props, ['meta', 'input'])}
    />
  );
};

export const FileCropInputField = ({
  name, ...props
}: Omit<BaseFieldProps<FileCropInputProps>, 'component'> & FileCropInputProps) => (
  <Field
    {...props}
    name={name}
    component={ReduxFormField}
  />
);
