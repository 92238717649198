import React, { PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageCropper, { Crop, makeAspectCrop } from 'react-image-crop';

import { Button } from '../../../button';
import Modal from '../../../modal';
import { FileInput } from '../file';
import FileInputPreview from './file-input-preview';

import { Attachment, FileInputValue } from '@common/types/objects';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { useAppSelector } from '@common/hooks';
import { setFormFileAttachments } from '../file/utils';

const DEFAULT_CROP = {
  width: 0,
  height: 0,
  x: 0,
  y: 0,
  unit: 'px' as 'px' | '%',
};

export type FileCropInputProps = PropsWithChildren<{
  value?: Attachment | null;
  aspectRatio?: number;
  submitButtonText?: string;
  preview?: boolean;
  unsplash?: boolean;
  onSubmit?: ((value?: FileInputValue | null, index?: number) => void);
  onChange?: ((value?: FileInputValue | null, index?: number) => void);
  processFile?: boolean;
}>;

const FileCropInput = ({
  aspectRatio = 1, submitButtonText, preview, children, unsplash, value,
  onChange, onSubmit, processFile,
}: FileCropInputProps) => {
  const { t } = useTranslation();
  const organisationId = useAppSelector(getCurrentOrgId);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<FileInputValue | null>(null);
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<Crop>({ aspect: aspectRatio, ...DEFAULT_CROP });

  const handleImageLoad = (newImage: HTMLImageElement) => {
    const imageRatio = newImage.width / newImage.height; // Aspect ratio for the selected image
    const isImageHorizontal = imageRatio > aspectRatio;
    const maxSize = isImageHorizontal ? newImage.height : newImage.width; // Select what the max size of the image can be
    const size = {
      width: isImageHorizontal ? maxSize * aspectRatio : maxSize,
      height: !isImageHorizontal ? maxSize * aspectRatio : maxSize,
    };

    const newCrop = makeAspectCrop({
      ...DEFAULT_CROP,
      aspect: aspectRatio,
      width: size.width,
      height: size.height,
    }, newImage.width, newImage.height);

    // Center the crop selection in the center
    if (newImage.width > newCrop.width) {
      newCrop.x = (newImage.width - newCrop.width) / 2;
    } else if (newImage.height > newCrop.height) {
      newCrop.y = (newImage.height - newCrop.height) / 2;
    }

    setCrop(newCrop);
    setImage(newImage);
  };

  const handleProcess = () => {
    if (!image || !crop) return;

    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const canvas = document.createElement('canvas');
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    const ctx = canvas.getContext('2d');
    ctx!.drawImage(
      image,
      crop.x! * scaleX,
      crop.y! * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0, 0, canvas.width, canvas.height,
    );

    setLoading(true);
    canvas.toBlob((blob) => {
      if (blob) {
        setFormFileAttachments({
          files: [blob as File],
          organisationId,
          onChange: onChange || onSubmit,
          options: { processFile },
        });
      }
      setIsVisibleModal(false);
    }, 'image/jpeg');
  };

  const handlePickFile = (newFile?: FileInputValue) => {
    setFile(newFile || null);
    setIsVisibleModal(true);
  };

  const fileInput = (
    <FileInput
      accept="image/jpg,image/jpeg,image/png,image/gif"
      processFile={false}
      unsplash={unsplash}
      onChange={handlePickFile}
    />
  );

  const trigger = useMemo(() => {
    if (Array.isArray(children) && typeof children[0] === 'string') return <a>{children[0]}</a>;
    if (!children && (!preview || !value)) return fileInput;
    return children;
  }, [children, preview, value]);

  return (
    <>
      <Modal
        list
        show={isVisibleModal}
        className="Form"
        title={t('common:form_input_image_choose_file')}
        onExited={() => {
          setFile(null);
          setImage(null);
          setCrop({ aspect: aspectRatio, ...DEFAULT_CROP });
          setLoading(false);
        }}
        onClose={() => setIsVisibleModal(false)}
        content={file ? (
          <div style={{ textAlign: 'center' }}>
            <ImageCropper
              keepSelection
              crop={crop}
              src={`${file.preview}`}
              onImageLoaded={handleImageLoad}
              onChange={(newCrop) => {
                if (newCrop.width === 0) return;
                setCrop(newCrop);
              }}
            />
          </div>
        ) : fileInput}
        footer={(
          <Button type="primary" onClick={handleProcess} isLoading={loading}>
            {submitButtonText || (
              preview ?
                t('common:form_input_image_save') :
                t('common:form_input_image_upload')
            )}
          </Button>
        )}
      >
        {trigger}
      </Modal>
      {preview && value && value.path && (
        <FileInputPreview
          value={value}
          handleClear={() => onChange && onChange(null)}
          handlePickFile={handlePickFile}
        />
      )}
    </>
  );
};

export default FileCropInput;
