import React, { memo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Spinner from '@common/components/spinner';
import Confirm from '../../../confirm-button';
import { Button } from '../../../button';
import { FileInput } from '../file';

import './file-input-preview.scss';

type FileInputPreviewProps = {
  value: any;
  handleClear: (...args: any[]) => any;
  handlePickFile: (...args: any[]) => any;
};

const FileInputPreview = memo(({
  value,
  handleClear,
  handlePickFile
}: FileInputPreviewProps) => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  return (
    <div className="Form__FileInput__Preview">
      { loading && <Spinner size="large" centered /> }
      <img
        style={{ maxWidth: '100%' }}
        src={value.path}
        alt="Preview"
        onLoad={() => setLoading(false)}
      />
      <div className="Form__FileInput__Preview__Overlay">
        <Confirm title={t('common:form_input_image_confirm_remove')} onConfirm={handleClear}>
          <Button type="warning">
            <Trans i18nKey="common:form_input_image_remove" />
          </Button>
        </Confirm>
        <FileInput
          accept="image/jpg,image/jpeg,image/png,image/gif"
          onChange={handlePickFile}
        >
          <label className="Button Button--inverted Button--size-default">
            <Trans i18nKey="common:form_input_image_upload_image" />
          </label>
        </FileInput>
      </div>
    </div>
  );
});

export default FileInputPreview;
