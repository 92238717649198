import React from 'react';
import { InjectedFormProps } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { Row, Group } from '@common/components/form';
import { Button } from '@common/components/button';
import { FileCropInput } from '@common/components/form/inputs/file-crop';
import type { ExtendedUser, FileInputValue } from '@common/types/objects';


type OwnProps = {
  user: ExtendedUser;
  setProfilePicture: (...args: any[]) => any;
  updatedPicture: FileInputValue | null;
};

type ProfilePictureFormProps = InjectedFormProps<{}, OwnProps> & OwnProps;

export const ProfilePictureForm = ({
  user,
  setProfilePicture,
  updatedPicture
}: ProfilePictureFormProps) => {
  const { t } = useTranslation();

  return (
    <div className="Form Form--horizontal PictureForm">
      <div className="Form__Left">
        <Row>
          <Group>
            <Row className="Form__Row PictureForm__Row">
              <div className="Form__Row__Preview">
                <img
                  src={updatedPicture?.preview || user.profile_img || ''}
                  alt={user.full_name}
                />
              </div>
              <div>
                <FileCropInput
                  onSubmit={setProfilePicture}
                  submitButtonText={t('core:confirm')}
                >
                  <Button type="default">{t('common:select_image')}</Button>
                </FileCropInput>
              </div>
            </Row>
          </Group>
        </Row>
      </div>
    </div>
  );
};
